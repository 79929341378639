@import '../../variables';

.App {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  // Include theme switch transition
  transition-duration: $theme-switch-transition-duration;
  transition-property: color, background-color;

  .main {
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: stretch;

    .calendar-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      overflow-y: auto;

      .calendar {
        min-height: $calendar-height;
      }
    }
  }

  .skeleton {
    overflow-x: hidden;
    overflow-y: auto;

    .skeleton-content {
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;

      .skeleton-content-inner {
        margin: auto auto;
        max-width: 640px;
        padding: 2rem;
        width: 100%;
      }
    }
  }
}

body.dark .App {
  background-color: $theme-dark-background;
  color: $theme-dark-foreground;
}

body.light .App {
  background-color: $theme-light-background;
  color: $theme-light-foreground;
}

.App.mobile {
  .tab-container {
    display: flex;
    height: 64px;
    border-bottom: 1px solid $color-border;

    .tab {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: $color-border;
      }
    }
  }

  .main {
    .sidebar {
      flex: 1;
      border-right: none;

      .scroller {
        width: auto;
      }
    }
  }
}

.Maintenance {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    .main-content {
      display: flex;
      align-items: center;

      .text {
        max-width: 50vw;
        margin-right: 36px;

        h1 {
          font-size: 56px;
          font-weight: 700;
          line-height: 75px;
        }

        p {
          font-size: 1.25em;
          font-weight: 400;
          line-height: 28px;

          span {
            color: #C56E5B;
          }
          
          a {
            color: #C56E5B;
          }
        }
      }

      img {
        max-width: 25vw;
      }
    }

    .footer {
      position: absolute;
      bottom: 32px;
      
      img {
        width: 200px;
      }
    }
  }
}

.mobile .Maintenance {
  overflow-y: scroll;
  .content {
    .main-content {
      flex-direction: column-reverse;
      display: flex;
      margin-bottom: 56px;

      .text {
        max-width: 85vw;
        margin: 0px;
        text-align: center;

        h1 {
          font-size: 28px;
          font-weight: 650;
          line-height: 42px;
        }

        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          margin: 8px 24px;
        }
      }

      img {
        width: 150px;
      }
    }

    .footer {
      bottom: 18px;
      
      img {
        width: 150px;
      }
    }
  }
}