@import '../../variables';

@mixin finals-margin($margin) {
  margin: $day-height + $margin $margin $margin $time-width + $margin;
}

.FinalsEmptyContainer {
  padding: 24px 8px;

  // Make the container grow/shrink to the full screen
  flex: 1 1;

  // Include colored links
  @extend %colored-link-container;
}

.FinalsEmpty {
  text-align: center;
}

.FinalsContainer {
  @include finals-margin(24px);
  flex: 1;
  position: relative;
  height: $calendar-height;
  border-bottom: 1px solid $color-border;
  border-right: 1px solid $color-border;

  &.preview {
    margin: 0;
    height: auto;
    border: none;
  }

  .ratio {
    display: block;
    visibility: hidden;
    width: 100%;
    height: auto;
  }

  .times {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -$time-width;
    right: 0;
    display: flex;
    flex-direction: column;

    .time {
      position: relative;
      flex: 1;
      margin-top: -0.5em;

      .label {
        display: block;
        width: $time-width;
        padding-right: 8px;
        font-size: .8em;
        font-weight: bold;
        text-align: right;
      }

      &:after {
        content: '';
        position: absolute;
        left: $time-width;
        right: 0;
        top: 0.5em;
        height: 1px;
        background-color: $color-border;
      }
    }
  }

  .days {
    position: absolute;
    left: 0;
    right: 0;
    top: -$day-height;
    bottom: 0;
    display: flex;

    .day {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;

      .label {
        height: $day-height;
        font-size: 1em;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 8px;
      }

      &:after {
        content: '';
        position: absolute;
        top: $day-height;
        bottom: 0;
        width: 1px;
        background-color: $color-border;
      }
    }
  }

  .meeting {
  }
}

.mobile .Finals:not(.capture) {
  @include finals-margin(0);
}
