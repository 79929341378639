@import '../../variables';

.data-not-persistent-notification {
  font-size: 0.9rem;
  margin: 0;
  text-align: left;
  line-height: 1.1rem;

  @include dark {
    color: rgba($theme-dark-foreground, 0.75);
  }

  @include light {
    color: rgba($theme-light-foreground, 0.75);
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--theme-fg);
  }

  & :not(:last-child) {
    margin-bottom: 8px;
  }

  a {
    @include dark {
      color: #89b6fd;
    }

    @include light {
      color: #1b61d1;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    color: var(--theme-fg);
  }

  pre {
    width: 100%;
    overflow-x: auto;
    font-size: 0.8rem;
  }

  & &--button {
    border: none;
    outline: none;
    padding: 10px 18px;
    cursor: pointer;
    box-shadow: 0px 0px 20px #00000033;
    transition: all 0.33s ease;
    border-radius: 8px;
    font-weight: 500;
    margin: 20px auto 8px;
    display: block;
  
    &:not(&--cancel) {
      background-color: #d36855;
      color: white;
  
      &:hover {
        background-color: #e2944b;
      }
    }
  }
}