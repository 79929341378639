@import '../../variables.scss';

.invitation-accept-modal-content {
  .heading {
    font-size: 24px;
    color: var(--theme-fg);
    text-align: center;
    font-weight: 700;
  }

  .modal-image {
    width: 250px;
    padding-top: 20px;
  }

  .error-sub-heading {
    color: var(--theme-fg);
    font-size: 18px;
    padding: 10px;
    font-weight: 700;
  }

  .error-message {
    text-align: center;
  }

  .sub-heading {
    color: var(--theme-fg);
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
  }

  .buzz-image {
    width: 200px;
    height: 200px;
    margin: 10px;
  }

  padding: 5% 10% 5% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remove-close-button {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 15px;
  color: $color-neutral;
  border-radius: 50px;
}
