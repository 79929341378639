@import "../../variables";

.CourseFilter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-top: 1px solid $color-border;

  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .8em;
    cursor: pointer;
    padding: 12px;
    opacity: .5;
    transition: opacity .2s;

    &.active,
    &:hover {
      opacity: 1;
    }

    .name {
      font-weight: bold;
      flex: 1;
      margin-right: 8px;

      .or {
        font-weight: normal;
      }
    }
  }

  .tag-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: -12px;
    padding: 8px;

    .tag {
      @include card;
      background-color: rgba($color-neutral, .5);
      padding: 4px 8px;
      opacity: .5;
      transition: opacity .2s;
      font-size: .8em;
      cursor: pointer;

      &.active {
        opacity: 1;
        font-weight: bold;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
