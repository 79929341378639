@import 'variables';

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  flex-shrink: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
select {
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  display: block;
  background: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  cursor: inherit;
}

@mixin content($text-color, $contrast-color) {
  &.default,
  .default {
    color: $text-color;
  }

  &.divider-bottom,
  .divider-bottom {
    border-bottom: 1px solid rgba($text-color, 0.2);

    &:last-child {
      border-bottom: none;
    }
  }

  &.nested,
  .nested {
    background-color: rgba($contrast-color, 0.5);
  }

  &.frame,
  .frame {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 2px solid $text-color;
    }
  }
}

.light-content {
  @include content($theme-light-foreground, white);
}

.dark-content {
  @include content($theme-dark-foreground, black);
}

body.dark {
  --theme-fg: #{$theme-dark-foreground};
  --theme-bg: #{$theme-dark-background};
  --theme-card-bg: #{$theme-dark-card-background};
  --dark-overlay: rgba(0, 0, 0, 0.2);
}

body.light {
  --theme-fg: #{$theme-light-foreground};
  --theme-bg: #{$theme-light-background};
  --theme-card-bg: #{$theme-light-card-background};
  --dark-overlay: rgba(0, 0, 0, 0.1);
}

*:not(.modal)::-webkit-scrollbar {
  display: none;
}
