@import '../../variables';

.drawer-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s linear;
  z-index: 100;

  // Include theme switch transition
  transition-duration: $theme-switch-transition-duration;
  transition-property: color, background-color;

  &.open {
    pointer-events: all;
    opacity: 1;
  }
}

.drawer-outer {
  background-color: var(--theme-bg);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 320px;
  z-index: 101;
  box-shadow: 0px 0px 8px 10px rgba(0, 0, 0, 0.08);

  transform: translateX(-105%);
  // Include theme switch transition in addition to the `transform` transition
  transition: transform 0.15s ease,
    color $theme-switch-transition-duration linear,
    background-color $theme-switch-transition-duration linear;

  &.open {
    transform: none;
  }
}

.drawer-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  height: $header-height;

  .drawer-close {
    padding: 0 16px;
    font-size: 1.7rem;
  }
}
