@import '../../variables';

.Header {
  height: $header-height;
  padding: 0 16px 0 0;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid $color-border;

  .logo {
    font-size: 1.5em;
    font-weight: bold;
    white-space: pre;
    padding: 0 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
  }

  .credits {
    padding: 12px;
    display: flex;
    align-items: center;
  }
}

.mobile .Header {
  padding: 0 8px;

  .logo {
    display: none;
  }

  .credits {
    display: none;
  }
}

.nav-menu-button {
  // Increase the tap target size
  margin-left: -8px;
  padding: 0 16px;

  // Increase the icon size
  font-size: 1.7rem;
}

.version-switch {
  flex-shrink: 1;
  min-width: 0;
}
