@import "../../variables";

.Instructor {
  display: flex;
  flex-direction: column;

  .instructor-row {
    padding: 4px;
    display: flex;

    .gpa {
      font-size: .8em;
    }
  }

  .section-container {
    position: relative;
    display: flex;
    flex-direction: column;

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -3px;
      right: -3px;
      content: '';
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, $opacity-divider);
      pointer-events: none;
    }

    .excluded-section-container {
      padding: 8px;

      .excluded-section {
        opacity: $opacity-inactive;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }

        &:not(:last-child):after {
          display: inline-block;
          content: ', ';
          white-space: pre-wrap;
          text-decoration: none;
        }
      }
    }
  }
}
