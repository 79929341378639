@import '../../variables';

.ActionRow {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 4px;

  .action-row-header {
    padding: 4px;
    display: flex;
    align-items: center;

    .label {
      flex: 1;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .actions {
      align-self: stretch;
      display: flex;
      align-items: stretch;

      .action {
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;
        background: none;
        padding: 0 8px;
        opacity: $opacity-inactive;
        font-size: .8em;

        &:first-child {
          position: static;
          flex: 1;

          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
