@import "../../variables";

.Section {
  .section-details {
    padding: 4px;
    display: flex;

    .delivery-mode {
      flex: 1;
      font-size: .8em;
      margin-right: 8px;
    }

    .meeting-container {
      display: flex;
      flex-direction: column;

      .meeting {
        display: flex;
        justify-content: flex-end;

        .days {
          font-size: .8em;
          margin-right: 8px;
        }

        .period {
          font-size: .8em;
          min-width: 8em;
        }
      }
    }

    .tooltip {
      opacity: 0.95;
      border-radius: 8px;
      white-space: nowrap;
    }

    tr {
      :nth-child(1) {
        text-align: right;
        padding-right: 3px;
      }

      :nth-child(2) {
        text-align: left;
        padding-left: 3px;
      }
    }
  }
}
