@import "../../variables";

.EventAdd {
  display: flex;
  flex-direction: column;

  .add {
    @include card;
    background-color: $color-border;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    table {
      border-collapse: collapse;
      table-layout: auto;
      width: 100%;

      tr:last-child {
        border-bottom: none;
      }

      tr {
        border-bottom: 1px solid $color-border;
    
        .label {
          opacity: .5;
          transition: opacity .2s;
          font-weight: bold;
          padding: 10px;
          font-size: .8em;
  
          &.active {
            opacity: 1;
          }
        }
  
        .input {
          display: flex;
          align-items: center;
          position: relative;
    
          input {
            position: relative;
            flex: 1;
            background: none;
            border: none;
            outline: none;
            font-size: .9rem;
            padding: 10px 8px;
            height: 100%; 
    
            &::placeholder {
              font-weight: normal;
              color: $color-neutral;
            }
          }

          input[type="time"] {
            min-height: 2.5rem;

            &::-webkit-calendar-picker-indicator {
              display: none;
            }

            &::-webkit-date-and-time-value {
              text-align: left;
            }
          }
        }

        .tag-container {
          display: flex;
          flex-wrap: wrap;
          padding: 4px;

          .tag:first-child {
            margin-left: 0;
          }
      
          .tag {
            @include card;
            background-color: rgba($color-neutral, .5);
            padding: 4px 8px;
            opacity: .5;
            transition: opacity .2s;
            font-size: .8em;
            cursor: pointer;
      
            &.active {
              opacity: 1;
              font-weight: bold;
            }
      
            &:hover {
              opacity: 1;
            }
          }
        }

        .submit {
          width: 100%;

          .button {
            float: right;
            display: block;
            background-color: #589BD5;
            margin: 10px 8px;         
            padding: 5px 25px;
            border-radius: 6px;
            color: white;
          }

          .error {
            color: #FF9292;
            font-size: small;
            padding: 10px 8px;
          }
        }
      }
    }
  }
}
