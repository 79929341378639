@import '../../variables';

.warning-header {
  text-align: center;

  h4 {
    font-size: 1.4rem;
    margin-top: 12px;
    margin-bottom: 16px;
  }

  svg {
    font-size: 3rem;
    margin: 0;

    @include dark {
      color: rgba(255, 242, 128, 0.5);
    }

    @include light {
      color: rgba(119, 128, 0, 0.5);
    }
  }
}
