@import "../../variables";

.Course {
  @include card;
  display: flex;
  flex-direction: column;

  .course-row {
    padding: 4px;
    display: flex;

    > * {
      font-size: .8em;
      white-space: nowrap;

      &:first-child {
        flex: 1;
        margin-right: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .palette {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .hover-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -3px;
      right: -3px;
      content: '';
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, $opacity-divider);
      pointer-events: none;
    }

    .excluded-instructor-container {
      padding: 8px;

      .excluded-instructor {
        opacity: $opacity-inactive;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          opacity: 1;
          text-decoration: none;
        }

        &:not(:last-child):after {
          display: inline-block;
          content: ', ';
          white-space: pre-wrap;
        }
      }
    }
  }
}
