@import '../../variables';

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  transition: background-color .2s;
  user-select: none;

  &.disabled {
    cursor: default;
    color: inherit;
    opacity: $opacity-disabled;
  }

  &:hover {
    background-color: $color-border;
  }
}
