@import '../../variables';

.TimeBlocks {
  &.overlay {
    .meeting {
      opacity: $opacity-disabled;
    }
  }

  .tooltip {
    opacity: 0.85;
    border-radius: 8px;
    z-index: 2;
    cursor: unset;
  }

  tr {
    :nth-child(1) {
      text-align: right;
      padding-right: 3px;
    }

    :nth-child(2) {
      text-align: left;
      padding-left: 3px;
    }
  }

  .meeting {
    position: absolute;
    width: 20%;
    height: 100px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--meeting-color, $color-neutral);
    outline: none;
    border: none;
    text-align: left;
    padding: 0;
    border-top: 1px solid $color-border;
    border-left: 1px solid $color-border;

    &:focus {
      // Remove the default focus style, since selecting the meeting
      // adds its own focus style:
      outline: none;
    }

    // Add a box-shadow border to the meeting block when it is highlighted
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: -1;
      box-shadow: 0 0 0 6px var(--meeting-color, $color-neutral);
      border-radius: 2px;
      transition: opacity 0.2s ease-in-out;
    }

    &--selected {
      &::after {
        opacity: 0.5;
      }

      & + .tooltip {
        opacity: 0.95;
      }
    }

    &--clone {
      & {
        opacity: 0.5;
      }
    }

    &--dragging {
      & {
        background-color: transparent;
        border: 2px solid var(--meeting-color, $color-neutral);
      }
    }

    :hover {
      cursor: pointer;
    }

    .meeting-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      padding: 0 8px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.72em;
      }

      .ids {
        display: flex;
        font-weight: bold;
        overflow: hidden;

        span {
          font-size: 0.8em;
        }
      }
    }

    &.M {
      left: 0;
    }

    &.T {
      left: 20%;
    }

    &.W {
      left: 40%;
    }

    &.R {
      left: 60%;
    }

    &.F {
      left: 80%;
    }
  }
}

.mobile .TimeBlocks:not(.capture) {
  .meeting {
    .meeting-wrapper {
      padding: 2px;
      height: 100%;

      span {
        white-space: normal;
      }
    }
  }
}
