@import '../../variables.scss';

.login-modal-content {
  &__firebase-ui {
    .firebaseui-idp-github.firebaseui-id-idp-button {
      // Fix the GitHub button to not be the same color as the background
      // when in dark mode.
      @include dark {
        background-color: #505050 !important;
      }
    }

    .invisible-input {
      // opacity: 0;
      // pointer-events: none;
      position: absolute;
    }

    .firebaseui-container {
      @include dark {
        color: white;
        background-color: $theme-dark-background;

        .mdl-button--primary.mdl-button--primary {
          color: white;
        }

        .firebaseui-input,
        .mdl-textfield__label {
          color: white;
        }

        .firebaseui-text {
          color: rgba(white, 0.7);
        }
      }
    }

    // Remove the bottom margin on both the last button
    // and on the outer list element::after
    .firebaseui-idp-list > .firebaseui-list-item,
    .firebaseui-tenant-list > .firebaseui-list-item {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .firebaseui-idp-list {
      margin-bottom: 0;
    }

    .mdl-card {
      @include popup;

      @include dark {
        background-color: $theme-dark-card-background;
      }
    }
  }

  &__body {
    text-align: center;
    margin-bottom: 28px;
  }

  .underline {
    text-decoration: underline;
    font-weight: 700;
    color: #FFFFFF;
  }

  .compare-text {
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 1px 0px 1px;
    color: #FFFFFF;
  }

  .compare-subtext {
    width: 330px;
    margin: 0 auto;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    
  }
}
