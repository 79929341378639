@import '../../variables.scss';

.InformationModal {
  .close-button {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 12px;
    top: 12px;
    padding: 15px;
    color: $color-neutral;
    border-radius: 50px;
  }
  .information-content {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding-left: 15%;
    padding-right: 15%;
    p {
      margin: 0px;
      width: 50%;
    }
    .information-images {
      display: flex;
      overflow: hidden;
      align-items: center;
      .information-image {
        width: auto;
        height: 25vh;
        margin-left: 16px;
        display: block;
        overflow: hidden;
      }
    }
  }
}

.InformationModal.mobile {
  align-items: center;
  .information-content {
    display: block;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    p {
      margin: auto;
      width: 80vw;
      text-align: center;
    }
    .information-images {
      display: flex;
      justify-content: space-evenly;
      .information-spacer {
        flex: .5;
      }
      .information-image {
        width: auto;
        height: 45vw;
        margin: 16px 0px 0px 0px;
        display: block;
        overflow: hidden;
      }
    }
  }
}