.loading-display {
  h4 {
    font-size: 1.4rem;
    margin-top: 12px;
    margin-bottom: 8px;
  }

  p {
    font-size: 0.9rem;
    margin: 0;
    opacity: 0.8;
  }
}
