@import '../../variables';

.comparison-panel {
  display: flex;
  flex-direction: row;

  .drawer {
    width: 13px;
    border-width: 0px 0px 0px 1px;
    border-color: rgba(255, 255, 255, 0.5);
    border-style: solid;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.opened {
      border-color: $color-border;
      border-width: 0px 1px 0px 1px;
    }

    &:hover {
      background: $color-border;
    }

    .drawer-line {
      flex: 1;
      width: 5px;
      border-width: 0px 1px 0px 1px;
      border-color: rgba(255, 255, 255, 0.5);
      border-style: solid;
      &.opened {
        border-color: $color-border;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      height: 48px;
      transform: scale(0.9, 1.5);

      .arrow {
        position: absolute;
        height: 10px;
        width: 10px;
        right: -8px;
        border-width: 0px 2px 2px 0px;
        border-color: rgba(255, 255, 255, 1);
        border-style: solid;
        transform: rotate(135deg);

        &.right {
          left: -8px;
          transform: rotate(-45deg);
          border-color: $color-border;
        }
      }
    }

    .tooltip {
      background: black;
      border-radius: 4px;
      z-index: 10;

      p {
        margin: 0px;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .panel {
    display: flex;
    flex: 0 0 1;
    flex-direction: column;
    align-items: stretch;
    width: 256px;
    transition: width 0.15s;
    overflow-y: auto;

    .comparison-header {
      display: flex;
      align-items: center;
      margin: 11px 12px 20px 12px;

      p {
        margin: 0px;
        overflow: hidden;

        &.header-title {
          flex: 1;
          font-size: 16px;
        }

        &.header-text {
          font-size: 12px;
          margin-right: 4px;
        }
      }

      .switch {
        display: inline-block;
        height: 19px;
        position: relative;
        width: 43px;
      }

      .switch input {
        display: none;
      }

      .slider {
        background-color: $color-neutral;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.4s;
        border-radius: 19px;
      }

      .slider:before {
        background-color: #ffffff;
        bottom: 2px;
        content: '';
        height: 15px;
        left: 2px;
        position: absolute;
        transition: 0.4s;
        width: 15px;
        border-radius: 50%;
      }

      input.checked + .slider {
        background-color: #589bd5;
      }

      input.checked + .slider:before {
        transform: translateX(24px);
      }
    }

    .comparison-overlay {
      background-color: var(--theme-bg);

      pointer-events: none;
      opacity: 0;
      transition-duration: 0.15s, $theme-switch-transition-duration,
        $theme-switch-transition-duration;
      transition-property: opacity, color, background-color;

      &.left {
        position: fixed;
        top: 64px;
        left: 0px;
        width: 320px;
        bottom: 41px;
      }

      &.right {
        position: absolute;
        top: 100px;
        width: 256px;
        height: 360px;
      }

      &.open {
        pointer-events: all;
        opacity: 0.4;
      }
    }

    .overlay-tooltip {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 4px;
      font-size: 12px;
      text-align: center;
      p {
        margin: 0px;
      }
    }

    &.closed {
      width: 0px;
    }

    .combination {
      flex: 1;

      .content-title {
        font-weight: 700;
        font-size: 14px;
        margin: 18px 12px 4px 12px;
        border-bottom: 2px solid $color-border;
      }

      .CombinationContainer {
        height: 100%;
      }
    }
  }
  .invite-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .invite-button {
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: var(--theme-fg);
      border-radius: 10px;
      background-color: $color-border;
      display: flex;
      padding: 10px 47px 10px 47px;
      border: none;
      gap: 5px;

      &:hover {
        @include dark {
          background: $modal-foreground-color-light;
        }

        @include light {
          background: $modal-foreground-color-dark;
        }
      }
    }
  }
}
