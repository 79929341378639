@import '../../variables';

.Attribution {
    width: 100%;
    min-height: 30px;
    padding-left: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: inherit;
    border-top: 1px solid $color-border;
    font-size: .8em;
    white-space: pre-wrap;

    p {
        display: flex;
        max-width: fit-content;
        flex-wrap: wrap;
        justify-content: center;
    }

    .githubText {
        margin-left: 5px;
    }
}